import React from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO/SEO';

export default () => {
  return (
    <Layout>
      <SEO title='Privacy' pathname='/privacy/' />
      <div style={{ margin: '0 auto', maxWidth: '800px' }}>
        <h2>Privacy</h2>
        <p>
          I have decided to remove Google Analytics from my website, and therefore I use no cookies
          to track my website usage.
        </p>
        <p>No privacy or tracking to worry about, just enjoy my site! </p>
      </div>
    </Layout>
  );
};
